import React from 'react';

import { container, titleText, contentBox } from './szlachetna-content.module.scss';

import SzlachetnaTitle from "../atoms/szlachetna-title";

interface ISzlachetnaContentProps {
    className?: string;
    title?: React.ReactNode;
    content?: React.ReactNode;
}

const SzlachetnaContent: React.FC<ISzlachetnaContentProps> = ({ className = '', title, content }) => {
    return (
        <div className={`${container} ${className}`}>
            <SzlachetnaTitle className={titleText}>
                {title}
            </SzlachetnaTitle>
            <div className={contentBox}>
                {content}
            </div>
        </div>
    );
};

export default SzlachetnaContent;