import React, { useRef, useState, useCallback } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import {
    container,
    header,
    text,
    videoBox,
    video,
    content,
    slider,
    card,
    name,
    recipe,
    slide,
    animate,
    image,
    ratio,
} from './szlachetna-drinks.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron-right.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { ImageDataLike } from '../../models/image-data-like.model';
import { useIntersectionObserver } from '../../hooks/use-intersection-observer';
import { getNodes } from '../../utils/get-nodes';

import SzlachetnaDecoTitle from '../atoms/szlachetna-deco-title';
import Markdown from '../hoc/markdown';
import RatioImage from '../atoms/ratio-image';

const videoSrc = '/videos/szlachetna-drinks.mp4';

interface ISzlachetnaDrinksProps {
    className?: string;
}

interface ISzlachetnaDrinksQueryResult {
    allFile: IQueryAllResult<ImageDataLike>;
}

const SzlachetnaDrinks: React.FC<ISzlachetnaDrinksProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { allFile } = useStaticQuery<ISzlachetnaDrinksQueryResult>(query);
    const drinkImages = getNodes(allFile);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isContainerIntersecting, setIsContainerIntersecting] = useState(false);

    const handleObserveContainer = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsContainerIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({
        element: containerRef.current,
        callback: handleObserveContainer,
        config: { threshold: 0.35 },
    });

    const drinks = [
        {
            name: t('szlachetna.drinks.name.1'),
            recipe: t('szlachetna.drinks.recipe.1'),
            image: drinkImages[0],
        },
        {
            name: t('szlachetna.drinks.name.2'),
            recipe: t('szlachetna.drinks.recipe.2'),
            image: drinkImages[1],
        },
        {
            name: t('szlachetna.drinks.name.3'),
            recipe: t('szlachetna.drinks.recipe.3'),
            image: drinkImages[2],
        },
    ];

    return (
        <div
            ref={containerRef}
            className={`${container} ${className} ${isContainerIntersecting ? animate : ''}`}
        >
            <div className={content}>
                <div className={videoBox}>
                    <video
                        className={video}
                        src={videoSrc}
                        playsInline={true}
                        muted={true}
                        autoPlay={true}
                        controls={false}
                        loop={true}
                    />
                </div>
                <div className={header}>
                    <SzlachetnaDecoTitle>{t('szlachetna.drinks.title')}</SzlachetnaDecoTitle>
                    <p className={text}>{t('szlachetna.drinks.text')}</p>
                </div>
                <Swiper
                    className={slider}
                    breakpoints={{
                        0: { slidesPerView: 1, spaceBetween: 12 },
                        600: { slidesPerView: 2, spaceBetween: 20 },
                        1024: { slidesPerView: 3, spaceBetween: 65 },
                    }}
                >
                    {drinks.map((drink, index) => {
                        return (
                            <SwiperSlide key={`slide-bottle-${index}`} className={slide}>
                                <div className={card}>
                                    <RatioImage
                                        className={image}
                                        ratioClass={ratio}
                                        image={getImage(drink.image)}
                                    />
                                    <h3 className={name}>{drink.name}</h3>
                                    <Markdown
                                        className={recipe}
                                        components={{
                                            li: ({ children }) => {
                                                return (
                                                    <li>
                                                        <ChevronIcon />
                                                        {children}
                                                    </li>
                                                );
                                            },
                                        }}
                                    >
                                        {drink.recipe}
                                    </Markdown>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

const query = graphql`
    query {
        allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-szlachetna/szlachetna-drink-01.jpg"
                        "soplica-szlachetna/szlachetna-drink-02.jpg"
                        "soplica-szlachetna/szlachetna-drink-03.jpg"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(quality: 90, width: 400)
                    }
                }
            }
        }
    }
`;

export default SzlachetnaDrinks;
