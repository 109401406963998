// extracted by mini-css-extract-plugin
export var animate = "szlachetna-drinks-module--animate--1a6e6";
export var backgroundAnimation = "szlachetna-drinks-module--backgroundAnimation--854af";
export var card = "szlachetna-drinks-module--card--5ea1a";
export var container = "szlachetna-drinks-module--container--f93ad";
export var content = "szlachetna-drinks-module--content--82e40";
export var errorBlink = "szlachetna-drinks-module--error-blink--57ec2";
export var header = "szlachetna-drinks-module--header--84891";
export var image = "szlachetna-drinks-module--image--3e28e";
export var name = "szlachetna-drinks-module--name--70a5a";
export var ratio = "szlachetna-drinks-module--ratio--391b5";
export var recipe = "szlachetna-drinks-module--recipe--4e246";
export var slide = "szlachetna-drinks-module--slide--ef56b";
export var slider = "szlachetna-drinks-module--slider--e3209";
export var text = "szlachetna-drinks-module--text--d1a47";
export var video = "szlachetna-drinks-module--video--72fc9";
export var videoBox = "szlachetna-drinks-module--video-box--ee740";