import React from 'react';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container, contentDesktop, contentMobile, logo } from './szlachetna-intro.module.scss';
import SzlachetnaLogo from '../../assets/images/soplica-szlachetna/szlachetna-logo.svg';

import Markdown from "../hoc/markdown";
import SzlachetnaContent from "../molecules/szlachetna-content";

interface ISzlachetnaIntroProps {
    className?: string;
}

const SzlachetnaIntro: React.FC<ISzlachetnaIntroProps> = ({ className }) => {
    const { t } =useI18next();

    return (
        <div className={`${container} ${className}`}>
            <SzlachetnaContent
                title={
                    <>
                        <span>{t('szlachetna.intro.title.1')}</span><br/>
                        {t('szlachetna.intro.title.2')}
                    </>
                }
                content={
                    <>
                        <img className={logo} src={SzlachetnaLogo} alt="Soplica Szlachetna" />
                        <Markdown className={contentDesktop}>
                            {t('szlachetna.intro.content.desktop')}
                        </Markdown>
                        <Markdown className={contentMobile}>
                            {t('szlachetna.intro.content.mobile')}
                        </Markdown>
                    </>
                }
            />
        </div>
    );
};

export default SzlachetnaIntro;