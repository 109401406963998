import React, { useRef, useState, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, Swiper as SwiperCore } from 'swiper';
import 'swiper/css';

import {
    container,
    innerContainer,
    absoluteBox,
    grain01,
    grain02,
    sliderBox,
    slider,
    ratio,
    title,
    slide,
    card,
    navigation,
    navButton,
    prev,
    pagination,
    backgroundText,
    grainMono01,
    grainMono02,
    animationBox,
    animate,
} from './szlachetna-offer.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron-right.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { ImageDataLike } from '../../models/image-data-like.model';
import { getNodes } from '../../utils/get-nodes';
import { useIntersectionObserver } from '../../hooks/use-intersection-observer';

import SzlachetnaTitle from '../atoms/szlachetna-title';
import RatioImage from '../atoms/ratio-image';

const grain02Src = '../../assets/images/soplica-szlachetna/szlachetna-grain-02.png';
const grain03Src = '../../assets/images/soplica-szlachetna/szlachetna-grain-03.png';
const grainMono01Src = '../../assets/images/soplica-szlachetna/szlachetna-grain-mono-01.png';
const grainMono02Src = '../../assets/images/soplica-szlachetna/szlachetna-grain-mono-02.png';

interface ISzlachetnaOfferProps {
    className?: string;
}

interface ISzlachetnaOfferQueryResult {
    allFile: IQueryAllResult<ImageDataLike>;
}

SwiperCore.use([Autoplay, Navigation, Pagination]);

const SzlachetnaOffer: React.FC<ISzlachetnaOfferProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { allFile } = useStaticQuery<ISzlachetnaOfferQueryResult>(query);
    const bottleImages = getNodes(allFile);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const paginationRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);
    const [isContainerIntersecting, setIsContainerIntersecting] = useState(false);
    const [isSliderIntersecting, setIsSliderIntersecting] = useState(false);

    const handleObserveContainer = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsContainerIntersecting(entry.isIntersecting);
        });
    }, []);

    const handleObserveSlider = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsSliderIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({
        element: containerRef.current,
        callback: handleObserveContainer,
        config: { threshold: 0.35 },
    });
    useIntersectionObserver({
        element: sliderRef.current,
        callback: handleObserveSlider,
        config: { threshold: 0.35 },
    });

    return (
        <div
            ref={containerRef}
            className={`${container} ${className} ${isContainerIntersecting ? animate : ''}`}
        >
            <div className={`${absoluteBox} ${grain01}`}>
                <div className={animationBox}>
                    <StaticImage src={grain02Src} alt="" />
                </div>
            </div>
            <div className={`${absoluteBox} ${grain02}`}>
                <div className={animationBox}>
                    <StaticImage src={grain03Src} alt="" />
                </div>
            </div>
            <div className={innerContainer}>
                <SzlachetnaTitle className={title}>{t('szlachetna.offer.title')}</SzlachetnaTitle>
                <div
                    ref={sliderRef}
                    className={`${sliderBox} ${isSliderIntersecting ? animate : ''}`}
                >
                    <p className={backgroundText}>Szlachetna</p>
                    <div className={`${absoluteBox} ${grainMono01}`}>
                        <StaticImage src={grainMono01Src} alt="" />
                    </div>
                    <div className={`${absoluteBox} ${grainMono02}`}>
                        <StaticImage src={grainMono02Src} alt="" />
                    </div>
                    <Swiper
                        className={slider}
                        modules={[Autoplay, Navigation, Pagination]}
                        pagination={{
                            el: paginationRef.current,
                            clickable: true,
                        }}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        breakpoints={{
                            0: { slidesPerView: 3 },
                            600: { slidesPerView: 5 },
                        }}
                    >
                        {bottleImages.map((bottleImage, index) => {
                            const image = getImage(bottleImage);
                            return (
                                <SwiperSlide key={`slide-bottle-${index}`} className={slide}>
                                    <div className={card}>
                                        <RatioImage
                                            image={image}
                                            ratioClass={ratio}
                                            objectFit="scale-down"
                                        />
                                        <p>{t(`szlachetna.offer.bottle.${index + 1}`)}</p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className={navigation}>
                        <button ref={prevRef} className={`${navButton} ${prev}`}>
                            <ChevronIcon />
                        </button>
                        <div ref={paginationRef} className={pagination} />
                        <button ref={nextRef} className={`${navButton}`}>
                            <ChevronIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const query = graphql`
    query {
        allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-szlachetna/szlachetna-offer-01.png"
                        "soplica-szlachetna/szlachetna-offer-02.png"
                        "soplica-szlachetna/szlachetna-offer-03.png"
                        "soplica-szlachetna/szlachetna-offer-04.png"
                        "soplica-szlachetna/szlachetna-offer-05.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(quality: 90)
                    }
                }
            }
        }
    }
`;

export default SzlachetnaOffer;
