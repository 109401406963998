import React, { CSSProperties } from 'react';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';

import { ratioContainer, imgBox, img } from './ratio-image.module.scss';

interface IRatioImageBase {
    className?: string;
    ratioClass?: string;
    alt?: string;
    objectFit?: CSSProperties['objectFit'];
    objectPosition?: CSSProperties['objectPosition'];
    loading?: GatsbyImageProps['loading'];
    draggable?: boolean;
    id?: string;
}

interface IGatsbyRatioImage extends IRatioImageBase {
    image: IGatsbyImageData;
    src?: never;
}

interface INativeRatioImage extends IRatioImageBase {
    image?: never;
    src?: string;
}

export type TRatioImageProps = IGatsbyRatioImage | INativeRatioImage;

const RatioImage: React.FC<TRatioImageProps> = ({
    className = '',
    ratioClass = '',
    image,
    src = '',
    alt = '',
    objectFit = 'cover',
    objectPosition = 'center center',
    loading,
    draggable = true,
    id,
}) => {
    return (
        <div className={className}>
            <div className={`${ratioContainer} ${ratioClass}`}>
                <div className={`${imgBox}`}>
                    {image && (
                        <GatsbyImage
                            className={img}
                            image={image}
                            alt={alt || ''}
                            objectFit={objectFit}
                            objectPosition={objectPosition}
                            loading={loading}
                            draggable={draggable}
                            id={id}
                        />
                    )}
                    {!image && src && (
                        <img
                            id={id}
                            className={img}
                            src={src}
                            alt={alt || ''}
                            loading={loading}
                            style={{
                                objectFit,
                                objectPosition,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RatioImage;
