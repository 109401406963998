import React, { useRef, useCallback, useState } from 'react';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    absoluteBox,
    grainMono01,
    grainMono02,
    animate,
    bottles,
} from './szlachetna-cheers.module.scss';
import { useIntersectionObserver } from "../../hooks/use-intersection-observer";

import SzlachetnaTitle from "../atoms/szlachetna-title";

const grainMono01Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-01.png";
const grainMono02Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-02.png";
const bottlesSrc = "../../assets/images/soplica-szlachetna/szlachetna-cheers.png";

interface ISzlachetnaCheersProps {
    className?: string;
}

const SzlachetnaCheers: React.FC<ISzlachetnaCheersProps> = ({ className = '' }) => {
    const { t } =useI18next();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isContainerIntersecting, setIsContainerIntersecting] = useState(false);

    const handleObserveContainer = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsContainerIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({ element: containerRef.current, callback: handleObserveContainer, config: { threshold: 0.35 } });

    return (
        <div ref={containerRef} className={`${container} ${className} ${isContainerIntersecting ? animate : ''}`}>
            <div className={`${absoluteBox} ${grainMono01}`}>
                <StaticImage src={grainMono01Src} alt="" />
            </div>
            <div className={`${absoluteBox} ${grainMono02}`}>
                <StaticImage src={grainMono02Src} alt="" />
            </div>
            <SzlachetnaTitle>
                <span>{t('szlachetna.cheers.title.1')}</span><br/>
                {t('szlachetna.cheers.title.2')}
            </SzlachetnaTitle>
            <StaticImage className={bottles} src={bottlesSrc} alt="" quality={90} />
        </div>
    );
};

export default SzlachetnaCheers;