import React from 'react';
import { graphql } from 'gatsby';

import {
    container,
    intro,
    bottle,
    grains,
    offer,
    drinks,
    cheers,
    spin,
    inspiration,
} from './soplica-szlachetna.module.scss';
import { IPage } from '../models/page.model';

import GorzkaHeader from '../components/organisms/gorzka-header';
import GorzkaHero from '../components/organisms/gorzka-hero';
import SzlachetnaIntro from '../components/organisms/szlachetna-intro';
import SzlachetnaBottle from '../components/organisms/szlachetna-bottle';
import SzlachetnaGrains from '../components/organisms/szlachetna-grains';
import SzlachetnaInspiration from '../components/organisms/szlachetna-inspiration';
import SzlachetnaOffer from '../components/organisms/szlachetna-offer';
import SzlachetnaDrinks from '../components/organisms/szlachetna-drinks';
import SzlachetnaCheers from '../components/organisms/szlachetna-cheers';
import GorzkaFooter from '../components/organisms/gorzka-footer';
import GorzkaBottleSpin from '../components/organisms/gorzka-bottle-spin';
import SEO from '../components/seo';

interface ISoplicaSzlachetnaProps {
    readonly data: {
        page: IPage | null;
    };
}

const SoplicaSzlachetna: React.FC<ISoplicaSzlachetnaProps> = ({ data }) => {
    const { page } = data;

    return (
        <>
            <SEO meta={page?.meta} />
            <div className={container}>
                <GorzkaHeader />
                <GorzkaHero />
                <SzlachetnaIntro className={intro} />
                <SzlachetnaBottle className={bottle} />
                <SzlachetnaGrains className={grains} />
                <SzlachetnaInspiration className={inspiration} />
                <GorzkaBottleSpin className={spin} theme="noble" />
                <SzlachetnaOffer className={offer} />
                <SzlachetnaDrinks className={drinks} />
                <SzlachetnaCheers className={cheers} />
                <GorzkaFooter theme="noble" />
            </div>
        </>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SoplicaSzlachetna;
