import React from 'react';

import { title } from './szlachetna-title.module.scss';

interface ISzlachetnaTitleProps {
    className?: string;
    TitleTag?: React.ElementType;
    children?: React.ReactNode;
}

const SzlachetnaTitle: React.FC<ISzlachetnaTitleProps> = ({ className = '', TitleTag = 'h2', children }) => {
    return (
        <TitleTag className={`${title} ${className}`}>
            {children}
        </TitleTag>
    );
};

export default SzlachetnaTitle;