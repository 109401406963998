// extracted by mini-css-extract-plugin
export var backgroundAnimation = "soplica-szlachetna-module--backgroundAnimation--20a43";
export var bottle = "soplica-szlachetna-module--bottle--8687d";
export var cheers = "soplica-szlachetna-module--cheers--d4142";
export var container = "soplica-szlachetna-module--container--d2eef";
export var drinks = "soplica-szlachetna-module--drinks--d4974";
export var errorBlink = "soplica-szlachetna-module--error-blink--0b15f";
export var grains = "soplica-szlachetna-module--grains--11343";
export var inspiration = "soplica-szlachetna-module--inspiration--4231f";
export var intro = "soplica-szlachetna-module--intro--0c5d6";
export var offer = "soplica-szlachetna-module--offer--22876";
export var spin = "soplica-szlachetna-module--spin--1cab7";