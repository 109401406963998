import React from 'react';

import { container, title, deco } from './szlachetna-deco-title.module.scss';
import SzlachetnaDeco from '../../assets/images/svg/szlachetna-deco.svg';

interface ISzlachetnaDecoTitleProps {
    className?: string;
    TitleTag?: React.ElementType;
    children?: React.ReactNode;
}

const SzlachetnaDecoTitle: React.FC<ISzlachetnaDecoTitleProps> = ({ className = '', children, TitleTag = 'h2' }) => {
    return (
        <div className={`${container} ${className}`}>
            <SzlachetnaDeco className={deco} />
            <TitleTag className={title}>
                {children}
            </TitleTag>
        </div>
    );
};

export default SzlachetnaDecoTitle;