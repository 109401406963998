import React, { useRef, useState, useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    container,
    innerContainer,
    title,
    cardBackground,
    grains,
    card,
    cardContent,
    cardVideo,
    video,
    image,
    absoluteBox,
    grain01,
    grain02,
    grainMono01,
    grainMono02,
    animationBox,
    animate,
} from './szlachetna-grains.module.scss';
import { useIntersectionObserver } from "../../hooks/use-intersection-observer";

import SzlachetnaTitle from "../atoms/szlachetna-title";

const cornSrc = "../../assets/images/soplica-szlachetna/szlachetna-corn-small.png";
const cornBgSrc = "../../assets/images/soplica-szlachetna/szlachetna-corn-bg.png";
const ryeSrc = "../../assets/images/soplica-szlachetna/szlachetna-rye-small.png";
const ryeVideoScr = "/videos/szlachetna-rye.mp4";
const wheatSrc = "../../assets/images/soplica-szlachetna/szlachetna-wheat-small.png";
const wheatBgSrc = "../../assets/images/soplica-szlachetna/szlachetna-wheat-bg.png";
const grain02Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-02.png";
const grain03Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-03.png";
const grainMono01Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-01.png";
const grainMono02Src = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-02.png";

interface ISzlachetnaGrainsProps {
    className?: string;
}

const SzlachetnaGrains: React.FC<ISzlachetnaGrainsProps> = ({ className = '' }) => {
    const { t } =useI18next();
    const [isContainerIntersecting, setIsContainerIntersecting] = useState(false);
    const [isListIntersecting, setIsListIntersecting] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    const handleObserveContainer = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsContainerIntersecting(entry.isIntersecting);
        });
    }, []);

    const handleObserveList = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsListIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({ element: containerRef.current, callback: handleObserveContainer, config: { threshold: 0.35 } });
    useIntersectionObserver({ element: listRef.current, callback: handleObserveList, config: { threshold: 0.1 } });

    return (
        <div className={`${container} ${className} ${isContainerIntersecting ? animate : ''}`} ref={containerRef}>
            <div className={`${absoluteBox} ${grain01}`}>
                <div className={animationBox}>
                    <StaticImage src={grain02Src} alt="" />
                </div>
            </div>
            <div className={`${absoluteBox} ${grain02}`}>
                <div className={animationBox}>
                    <StaticImage src={grain03Src} alt="" />
                </div>
            </div>
            <div className={innerContainer}>
                <SzlachetnaTitle className={title}>
                    <span>{t('szlachetna.grains.title.01')}</span><br />
                    {t('szlachetna.grains.title.02')}
                </SzlachetnaTitle>
                <div ref={listRef} className={`${grains} ${isListIntersecting ? animate : ''}`}>
                    <div className={card}>
                        <div className={cardBackground}>
                            <StaticImage src={cornBgSrc} alt="" />
                        </div>
                        <div className={cardContent}>
                            <StaticImage className={image} src={cornSrc} alt="" />
                            <p>{t('szlachetna.grains.corn')}</p>
                        </div>
                    </div>
                    <div className={`${card} ${cardVideo}`}>
                        <div className={`${absoluteBox} ${grainMono01}`}>
                            <StaticImage src={grainMono01Src} alt="" />
                        </div>
                        <div className={`${absoluteBox} ${grainMono02}`}>
                            <StaticImage src={grainMono02Src} alt="" />
                        </div>
                        <div className={cardBackground}>
                            <video
                                className={video}
                                src={ryeVideoScr}
                                playsInline={true}
                                muted={true}
                                autoPlay={true}
                                controls={false}
                                loop={true}
                            />
                        </div>
                        <div className={cardContent}>
                            <StaticImage className={image} src={ryeSrc} alt="" />
                            <p>{t('szlachetna.grains.rye')}</p>
                        </div>
                    </div>
                    <div className={card}>
                        <div className={cardBackground}>
                            <StaticImage src={wheatBgSrc} alt="" />
                        </div>
                        <div className={cardContent}>
                            <StaticImage  className={image} src={wheatSrc} alt="" />
                            <p>{t('szlachetna.grains.wheat')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SzlachetnaGrains;