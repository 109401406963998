// extracted by mini-css-extract-plugin
export var absoluteBox = "szlachetna-inspiration-module--absolute-box--e0fde";
export var animate = "szlachetna-inspiration-module--animate--0b88c";
export var backgroundAnimation = "szlachetna-inspiration-module--backgroundAnimation--c0154";
export var backgroundText = "szlachetna-inspiration-module--background-text--0f1d3";
export var bottleNew = "szlachetna-inspiration-module--bottle-new--64442";
export var bottleOld = "szlachetna-inspiration-module--bottle-old--9b182";
export var bottles = "szlachetna-inspiration-module--bottles--55730";
export var content = "szlachetna-inspiration-module--content--3172b";
export var contentBox = "szlachetna-inspiration-module--content-box--be352";
export var errorBlink = "szlachetna-inspiration-module--error-blink--1282f";
export var grainMono01 = "szlachetna-inspiration-module--grain-mono-01--c636b";
export var grainMono02 = "szlachetna-inspiration-module--grain-mono-02--05e1f";
export var grainMono03 = "szlachetna-inspiration-module--grain-mono-03--b593a";
export var grainMono04 = "szlachetna-inspiration-module--grain-mono-04--8c98c";
export var innerContainer = "szlachetna-inspiration-module--inner-container--7ae1b";
export var swirl = "szlachetna-inspiration-module--swirl--f2344";
export var text = "szlachetna-inspiration-module--text--28a36";
export var text01 = "szlachetna-inspiration-module--text--01--e0e40";
export var text02 = "szlachetna-inspiration-module--text--02--2f17c";