import { useEffect } from 'react';

interface IUseIntersectionObserverConfig {
    element: Element | null;
    callback: IntersectionObserverCallback;
    config?: IntersectionObserverInit;
}

export const useIntersectionObserver = ({ element, callback, config }: IUseIntersectionObserverConfig) => {
    useEffect(() => {
        if (!element) return;
        const observer = new IntersectionObserver(callback, config);
        observer.observe(element);
        return () => {
            if (!element)return;
            observer.unobserve(element);
        };
    }, [element, callback, config]);
};
