// extracted by mini-css-extract-plugin
export var absoluteBox = "szlachetna-offer-module--absolute-box--d6926";
export var animate = "szlachetna-offer-module--animate--53d24";
export var animationBox = "szlachetna-offer-module--animation-box--3d91b";
export var backgroundAnimation = "szlachetna-offer-module--backgroundAnimation--99f62";
export var backgroundText = "szlachetna-offer-module--background-text--69676";
export var card = "szlachetna-offer-module--card--77764";
export var container = "szlachetna-offer-module--container--0c791";
export var errorBlink = "szlachetna-offer-module--error-blink--fff12";
export var grain01 = "szlachetna-offer-module--grain-01--015ab";
export var grain02 = "szlachetna-offer-module--grain-02--ce56a";
export var grainMono01 = "szlachetna-offer-module--grain-mono-01--e8479";
export var grainMono02 = "szlachetna-offer-module--grain-mono-02--a8df3";
export var innerContainer = "szlachetna-offer-module--inner-container--664c5";
export var navButton = "szlachetna-offer-module--nav-button--1673d";
export var navigation = "szlachetna-offer-module--navigation--3d72d";
export var pagination = "szlachetna-offer-module--pagination--4071a";
export var prev = "szlachetna-offer-module--prev--adaf2";
export var ratio = "szlachetna-offer-module--ratio--24d31";
export var slide = "szlachetna-offer-module--slide--47167";
export var slider = "szlachetna-offer-module--slider--25d66";
export var sliderBox = "szlachetna-offer-module--slider-box--4161f";
export var title = "szlachetna-offer-module--title--9ffed";