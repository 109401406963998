import React, { useRef, useState, useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    container,
    imageBox,
    bottle,
    absoluteBox,
    grain01,
    grain02,
    weed01,
    weed02,
    backgroundBox,
    contentBox,
    backgroundImage,
    title,
    grainMono01,
    grainMono02,
    grainMono03,
    animate,
    animationBox,
} from './szlachetna-bottle.module.scss';
import { useIntersectionObserver } from "../../hooks/use-intersection-observer";

import SzlachetnaDecoTitle from "../atoms/szlachetna-deco-title";

const bottleSrc = "../../assets/images/soplica-szlachetna/szlachetna-bottle.png";
const grainSrc = "../../assets/images/soplica-szlachetna/szlachetna-grain-01.png";
const grainMonoSrc = "../../assets/images/soplica-szlachetna/szlachetna-grain-mono-01.png";
const weedSrc = "../../assets/images/soplica-szlachetna/szlachetna-weed.png";
const backgroundSrc = "../../assets/images/soplica-szlachetna/szlachetna-bottle-bg.png";

interface ISzlachetnaBottleProps {
    className?: string;
}

const SzlachetnaBottle: React.FC<ISzlachetnaBottleProps> = ({ className = '' }) => {
    const { t } =useI18next();
    const imageBoxRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isImageIntersecting, setIsImageIntersecting] = useState(false);
    const [isContentIntersecting, setIsContentIntersecting] = useState(false);

    const handleObserveImageBox = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsImageIntersecting(entry.isIntersecting);
        });
    }, []);

    const handleObserveContent = useCallback<IntersectionObserverCallback>((entries) => {
        entries.forEach((entry) => {
            setIsContentIntersecting(entry.isIntersecting);
        });
    }, []);

    useIntersectionObserver({ element: imageBoxRef.current, callback: handleObserveImageBox, config: { threshold: 0.35 } });
    useIntersectionObserver({ element: contentRef.current, callback: handleObserveContent, config: { threshold: 0.35 } });

    return (
        <div className={`${container} ${className}`}>
            <div ref={imageBoxRef} className={`${imageBox} ${isImageIntersecting ? animate : ''}`}>
                <div className={`${absoluteBox} ${grainMono01}`}>
                    <StaticImage src={grainMonoSrc} alt="" />
                </div>
                <div className={`${absoluteBox} ${grainMono02}`}>
                    <StaticImage src={grainMonoSrc} alt="" />
                </div>
                <div className={`${absoluteBox} ${grainMono03}`}>
                    <StaticImage src={grainMonoSrc} alt="" />
                </div>
                <div className={`${absoluteBox} ${weed01}`}>
                    <div className={animationBox}>
                        <StaticImage src={weedSrc} alt="" />
                    </div>
                </div>
                <div className={`${absoluteBox} ${weed02}`}>
                    <div className={animationBox}>
                        <StaticImage src={weedSrc} alt="" />
                    </div>
                </div>
                <div className={`${absoluteBox} ${grain01}`}>
                    <div className={animationBox}>
                        <StaticImage src={grainSrc} alt="" />
                    </div>
                </div>
                <div className={`${absoluteBox} ${grain02}`}>
                    <div className={animationBox}>
                        <StaticImage src={grainSrc} alt="" />
                    </div>
                </div>
                <div className={bottle}>
                    <div className={animationBox}>
                        <StaticImage src={bottleSrc} alt={t('szlachetna.bottle.alt')} />
                    </div>
                </div>
            </div>
            <div ref={contentRef} className={`${contentBox} ${isContentIntersecting ? animate : ''}`}>
                <div className={backgroundBox}>
                    <StaticImage className={backgroundImage} src={backgroundSrc} alt="" quality={90} />
                </div>
                <SzlachetnaDecoTitle className={title}>
                    {t('szlachetna.bottle.title.01')}<br/>
                    <span>{t('szlachetna.bottle.title.02')}</span>
                </SzlachetnaDecoTitle>
            </div>
        </div>
    );
};

export default SzlachetnaBottle;